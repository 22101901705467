import _ from 'lodash/fp'
import React, { Component } from 'react'


/**
 * A simple state container inspired by clojure atoms. Method names were chosen based on similarity
 * to lodash and Immutable. (deref => get, reset! => set, swap! => update, reset to go back to
 * initial value)
 */
export const atom = initialValue => {
  let value = initialValue
  let subscribers = []
  const set = newValue => {
    const oldValue = value
    value = newValue
    subscribers.forEach(fn => fn(newValue, oldValue))
  }
  return {
    subscribe: fn => {
      subscribers = _.union(subscribers, [fn])
    },
    unsubscribe: fn => {
      console.assert(_.includes(fn, subscribers), 'Function is not subscribed')
      subscribers = _.difference(subscribers, [fn])
    },
    get: () => value,
    set,
    update: fn => set(fn(value)),
    reset: () => set(initialValue)
  }
}

/**
 * HOC that injects the value of the given atom as a prop. When the atom changes, the wrapped
 * component will re-render
 */
export const connectAtom = (theAtom, name) => WrappedComponent => {
  class Wrapper extends Component {
    constructor(props) {
      super(props)
      this.state = { value: theAtom.get() }
    }

    static displayName = 'connectAtom()'

    componentDidMount() {
      theAtom.subscribe(this.handleChange)
    }

    componentWillUnmount() {
      theAtom.unsubscribe(this.handleChange)
    }

    handleChange = value => {
      this.setState({ value })
    }

    render() {
      const { value } = this.state

      return React.createElement(WrappedComponent, {...this.props, [name]: value})
    }
  }

  return Wrapper
}
