import {aFetch} from '../libs/fetch'
import * as Nav from '../libs/nav'
import Prism from 'prismjs'
import 'prismjs/themes/prism.css'
import React, {useEffect, useRef} from 'react'
import h from 'react-hyperscript-helpers'
import * as spinner from '../components/Spinner'


function logoBar(shouldLinkHome) {
  return h.div(
    {style: {backgroundColor: '#222', color: '#eee', display: 'flex', justifyContent: 'center'}},
    [
      h.div({style: {flexGrow: 1, maxWidth: 600}},
        [h.a(
          {
            href: shouldLinkHome ? '/' : undefined,
            className: 'logo-link', style: {color: 'inherit'},
            onClick: (e) => {
              if (e.altKey || e.ctrlKey || e.metaKey) return
              e.preventDefault()
              Nav.history.push('/')
            }
          },
          [h.h1({style: {fontSize: '400%', margin: '0.3em 0 0.2em 0', display: 'inline-block'}}, [
            h.span({style: {fontSize: 'large'}}, 'The'), 'Blog',
            h.span({style: {fontSize: 'large'}}, 'of'),
            'Mohs'])])])
    ]
  )
}

function blueBar() {
  return h.div({style: {height: 4, backgroundColor: '#30d0ff'}})
}

async function contentFetch(path, onNotFound) {
  const opName = 'contentfetch'
  spinner.startOperation(opName, 'Fetching content...')
  const url = path
  const res = await aFetch(url)
  if (res.status === 404) {
    onNotFound()
  }
  const html = await res.text()
  spinner.stopOperation(opName)
  return html
}

function makeLinksPushState(element) {
  function handleLinkClick(e) {
    if (e.altKey || e.ctrlKey || e.metaKey) return
    e.preventDefault()
    const url = e.currentTarget.href
    const path = url.startsWith(document.baseURI)
      ? url.substring(document.baseURI.length - 1) : url
    Nav.history.push(path)
  }

  element.querySelectorAll('a').forEach((e) => {
    e.addEventListener('click', handleLinkClick)
  })
}

function Root(props) {
  const bodyRef = useRef(null)

  async function fetch() {
    bodyRef.current.innerHTML = await contentFetch('content/root.html', props.onNotFound)
    makeLinksPushState(bodyRef.current)
  }

  useEffect(() => {fetch()}, [])

  return h.h(React.Fragment, [
    blueBar(),
    logoBar(false),
    h.div({style: {display: 'flex', justifyContent: 'center'}}, [
      h.div({className: 'content-container'}, [
        blueBar(),
        h.div({className: 'root', style: {margin: '1em 1ex 30vh 1ex'}}, [
          h.div({ref: bodyRef}),
        ]),
        blueBar()
      ])
    ])
  ])
}

let footerHtml = null

async function fetchFooter() {
  if (footerHtml === null) {
    const res = await aFetch('/content/footer.html')
    footerHtml = await res.text()
  }
  return footerHtml
}

function Content(props) {
  const bodyRef = useRef(null)
  const footerRef = useRef(null)

  async function fetch() {
    bodyRef.current.innerHTML = await contentFetch('/' + props[0] + '.html', props.onNotFound)
    Prism.highlightAll()
    bodyRef.current.querySelectorAll('script').forEach(script => eval(script.innerHTML)) // eslint-disable-line no-eval
    makeLinksPushState(bodyRef.current)
    footerRef.current.innerHTML = await fetchFooter()
  }

  useEffect(() => {fetch()}, [])

  const contactEmail = 'article-comments@davidmohs.com'
  return h.h(React.Fragment, [
    blueBar(),
    logoBar(true),
    h.div({style: {display: 'flex', justifyContent: 'center'}}, [
      h.div({className: 'content-container'}, [
        blueBar(),
        h.div({className: 'article', style: {margin: '0 1ex 30vh 1ex'}}, [
          h.div({ref: bodyRef}),
          h.hr({style: {marginTop: '4em'}}),
          h.div({className: 'essay-footer', ref: footerRef}),
        ]),
        blueBar()
      ])
    ])
  ])
}

export const addNavPaths = () => {
  Nav.defPath('root', {
    path: '/',
    component: Root,
    public: true
  })
  Nav.defPath('content', {
    path: '/(.+)',
    component: Content,
    public: true
  })
}
