// Leave undefined for http://localhost:8080/api when running locally, parsed from URL otherwise.
export const apiRoot = undefined

export function getApiRoot() {
  if (apiRoot) return apiRoot
  if (window.location.hostname === 'localhost') {
    return 'http://localhost:8080/api'
  } else {
    return window.location.origin + '/api'
  }
}
