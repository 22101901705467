let delaySeconds = 0

export function aFetch(url, options) {

  function fetchWithReject(resolve, reject) {
    fetch(url, options).then(resolve, error => {
      if (error instanceof DOMException && error.name === 'AbortError') {
        // no-op, this is from an aborted call
      } else {
        reject(error)
      }
    })
  }

  return new Promise((resolve, reject) => {
    if (delaySeconds <= 0) {
      fetchWithReject(resolve, reject)
    } else {
      setTimeout(() => fetchWithReject(resolve, reject), delaySeconds * 1000)
    }
  })
}

export function setDelaySeconds(seconds) {
  delaySeconds = seconds
}
window.setDelaySeconds = setDelaySeconds
