import {atom, connectAtom} from '../libs/atom'
import _ from 'lodash/fp'
import React, {Component} from 'react'
import {Spinner as SpinJsSpinner} from 'spin.js'
import 'spin.js/spin.css'
import styled from 'styled-components'


const SpinnerContainer = styled.span`
  position: relative;
  display: inline;
  // border: 1px solid red;
  // width: 20px;
  // height: 20px;
`

export class Spinner extends Component {
  render() {
    return (
      <SpinnerContainer ref='spinner'></SpinnerContainer>
    )
  }
  componentDidMount() {
    new SpinJsSpinner(this.props).spin(this.refs.spinner)
  }
}

const StyledPageSpinner = styled.div`
  position: fixed;
  bottom: 0.2rem;
  right: 0.2rem;
  font-size: small;
  background: #ddd;
  padding: 0.1rem 0.7rem 0.1rem 0.2rem;
  border-radius: 0.3rem;
`

const operations = atom([])

export function startOperation(name, message) {
  if (!_.find(x => x.name === name, operations.get())) {
    operations.update(x => _.concat(x, [{name, message}]))
  }
}
window.startOperation = startOperation

export function stopOperation(name) {
  operations.update(x => _.filter(x, y => y.name !== name))
}
window.stopOperation = stopOperation

export const PageSpinner = connectAtom(operations, 'ops')(({ops}) => {
  if (ops.length === 0) {
    return null
  } else {
    const message = ops[ops.length - 1].message
    return (
    <StyledPageSpinner>
      <span style={{marginRight: '0.8rem'}}>{message}</span>
      <Spinner scale='0.4'/>
    </StyledPageSpinner>
  )}
})
