import * as config from '../config'
import _ from 'lodash/fp'
import * as nav from '../libs/nav'
import React, { Component, Fragment } from 'react'
import 'codemirror/lib/codemirror.css'
import CodeMirror from 'codemirror/lib/codemirror'
import {aFetch} from '../libs/fetch'
import 'codemirror/mode/javascript/javascript'
import * as spinner from '../components/Spinner'
import styled from 'styled-components'


class RootDocs extends Component {
  render() {
    const rows = _.map(doc => {
      return (<tr key={doc.link + doc.method}>
        <td style={{textTransform: 'uppercase'}}>{doc.method}</td>
        <td>{doc.link}</td>
        <td><a href={`#apidocs?path=${doc.path}&method=${doc.method}`}>{doc.title}</a></td>
      </tr>)
    }, this.props.docs)
    return (
      <div>
        <table><tbody>{rows}</tbody></table>
      </div>
    )
  }
}

const StyledCodeMirror = styled.div`
  box-shadow: 0px 0px 5px 2px #ccc;
  max-width: 100ex;
  .CodeMirror {
    height: auto;
  }
`

class CodeMirrorComponent extends Component {
  render() {
    return (
      <StyledCodeMirror ref={el => this.cmEl = el}/>
    )
  }
  componentDidMount() {
    this.cm = CodeMirror(
      this.cmEl,
      {
        tabSize: 2,
        mode: 'javascript',
        readOnly: !this.props.onChange,
        lineWrapping: true,
        ..._.omit(['onChange', 'value'], this.props)
      }
    )
    this.cm.setValue(this.props.value)
    this.cm.on('change', (instance, changeObj) => this.handleChange(instance, changeObj))
  }
  shouldComponentUpdate(nextProps, nextState) {
    if (this.cm.getValue() === nextProps.value) {
      return false
    }
    return true
  }
  componentDidUpdate(prevProps) {
    this.cm.setValue(this.props.value)
  }
  handleChange(instance, changeObj) {
    const value = instance.getValue()
    if (value !== this.props.value) {
      this.props.onChange(value)
    }
  }
}

function stringifyWithIndent(object, extraIndent) {
  if (!object) return object
  const stringified = JSON.stringify(object, null, 2)
  if (extraIndent) {
    return stringified
      .split('\n')
      .map(x => extraIndent + x)
      .join('\n')
      .substring(extraIndent.length)
  } else {
    return stringified
  }
}

function getExampleCode(opts) {
  const {method, forcedResponseName} = opts
  const er = opts.exampleRequest || {}
  let fetchOpts = _.lowerCase(method) === 'get' ? {} : {method}
  if (er.body) {
    fetchOpts.body = '{{EXAMPLE-BODY-TOKEN}}'
  }
  fetchOpts.headers = er.headers
  const path = er.path || opts.path
  if (forcedResponseName) {
    fetchOpts.headers = {...fetchOpts.headers, 'X-Force-Response': forcedResponseName}
  }
  let fetchCall = _.isEmpty(fetchOpts) ? 'fetch(url)'
      : `fetch(url, ${stringifyWithIndent(fetchOpts)})`

  fetchCall = fetchCall.replace(
    '"{{EXAMPLE-BODY-TOKEN}}"', 'JSON.stringify(' + stringifyWithIndent(er.body, '  ') + ')'
  )
  return (
`const url = '${config.getApiRoot()}${path}'
const res = await ${fetchCall}
console.log(res.ok)
logJson(res)
`)
}

function encloseCode(code) {
  return (
`(async () => {
async function logJson(res) {
  const obj = await res.json().catch(() => null)
  if (obj) {
    console.log(obj)
  } else {
    console.warn('Could not parse JSON from response.')
  }
}
${code}
})()`
  )
}

class ApiDocs extends Component {
  constructor(props) {
    super(props)
    this.state = {code: ''}
  }
  render() {
    const homeLink = this.props.queryParams.path &&
      <a href='#apidocs' style={{fontSize: 'small', marginLeft: '1rem'}}>Docs Home</a>
    return (
      <div style={{padding: '0 1rem'}}>
        <h1>API Documentation {homeLink}</h1>
        {this.props.queryParams.path ? this.renderCodeArea() : this.renderDocsRoot()}
      </div>
    )
  }
  renderDocsRoot() {
    if (!this.state.docsRoot) return null
    return (
      <Fragment><RootDocs docs={this.state.docsRoot}/></Fragment>
    )
  }
  renderCodeArea() {
    const docs = this.state.docs
      ? JSON.stringify(_.omit(["exampleRequest"], this.state.docs), null, 2)
      : ''
    const buttons = this.state.docs && _.map(n => {
      return <button key={n} onClick={e => this.setCode(n)}>{n}</button>
    }, this.state.docs.forcedResponses)
    return (
      <Fragment>
        <CodeMirrorComponent value={docs}/>
        <div style={{marginTop: '2rem'}}></div>
        {buttons}
        <div style={{marginTop: '0.5rem'}}></div>
        <CodeMirrorComponent
          value={this.state.code}
          onChange={(code) => this.setState(state => ({code}))}
        />
        <div style={{marginTop: '0.5rem'}}></div>
        <button onClick={e => this.eval_()}>Eval</button>
        <div style={{marginTop: '50vh'}}></div>
      </Fragment>
    )
  }
  componentDidMount() {
    this.fetchDocs()
  }
  componentDidUpdate(prevProps) {
    if (this.props.queryParams !== prevProps.queryParams) {
      this.fetchDocs()
    }
  }
  async fetchDocs() {
    const opName = this.displayName + 'fetch'
    spinner.startOperation(opName, 'Fetching API docs...')
    const p = this.props.queryParams.path || ''
    const url = config.getApiRoot() + '/!docs' + p
    const m = this.props.queryParams.method
    const res = await aFetch(url + (m ? '?method=' + m : ''))
    const docs = await res.json()
    spinner.stopOperation(opName)
    if (p) {
      this.setState(state => ({docs, code: getExampleCode({...docs, path: p})}))
    } else {
      this.setState(state => ({docsRoot: docs, docs: null, code: ''}))
    }
  }
  codeDidChange(code) {
    if (this.state.code)
    this.setState(state => ({code}))
  }
  async eval_() {
    eval(encloseCode(this.state.code)) // eslint-disable-line no-eval
  }
  setCode(forcedResponseName) {
    this.setState(state => {
      return {
        code: getExampleCode({
          path: this.props.queryParams.path,
          method: state.docs.method,
          forcedResponseName
        })
      }
    })
  }
}

export const addNavPaths = () => {
  nav.defPath('apidocs', {
    path: '/apidocs',
    component: ApiDocs,
    public: true,
    title: 'API Documentation'
  })
}
