import _ from 'lodash/fp'
import React, { Component } from 'react';
import * as Nav from '../libs/nav'
import * as ApiDocs from '../pages/ApiDocs'
import * as Root from '../pages/Root'


// const pageWrapStyle = { minHeight: '100%', display: 'flex', flexDirection: 'column', flexGrow: 1 }
const pageWrapStyle = {}

const initNavPaths = () => {
  Nav.clearPaths()
  ApiDocs.addNavPaths()
  Root.addNavPaths()
}

export default class Router extends Component {
  constructor(props) {
    super(props)
    this.state = { pathname: undefined }
  }

  componentDidMount() {
    initNavPaths()
    this.setState({ pathname: Nav.history.location.pathname, search: Nav.history.location.search })
    this.unlisten = Nav.history.listen(
      ({ pathname, search }) => this.setState({ pathname, search })
    )
  }

  // FIXME - shouldn't be using unsafe methods
  UNSAFE_componentWillReceiveProps() { // eslint-disable-line camelcase
    initNavPaths()
  }

  componentDidUpdate(prevProps, prevState) {
    const { pathname, search } = this.state
    if (prevState.pathname === pathname) return

    const handler = Nav.findHandler(pathname)
    let title = ''

    if (handler && handler.title) {
      if (_.isFunction(handler.title)) {
        title = handler.title(Nav.getHandlerProps(handler, pathname, search))
      } else {
        title = handler.title
      }
      title += this.props.titleRoot ? ` | ${this.props.titleRoot}` : ''
    } else {
      title = this.props.titleRoot || 'Untitled React App'
    }
    document.title = title
  }

  componentWillUnmount() {
    this.unlisten()
  }

  render() {
    const { pathname, search } = this.state
    if (pathname === undefined) {
      return null
    }
    const handler = Nav.findHandler(pathname)
    if (!handler) {
      return (
        <div style={{ marginLeft: '1rem', ...pageWrapStyle }}>
          <h2 style={{color: '#eee', textAlign: 'center', marginTop: '20vh'}}>Page not found.</h2>
        </div>
      )
    }
    const el = (
      <div style={pageWrapStyle}>
        {React.createElement(
          handler.component,
          {key: pathname, onNotFound: () => this.setState({pathname: 'notfound'}),
            ...Nav.getHandlerProps(handler, pathname, search)}
        )}
      </div>
    )
    return handler.public ? el : <div><div>This should be authed:</div>{el}</div>
  }
}
