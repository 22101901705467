import React, { Fragment, Component } from 'react';
import Router from './components/Router'
import {PageSpinner} from './components/Spinner'

export default class App extends Component {
  render() {
    return (
      <Fragment>
        <Router titleRoot='The Blog of Mohs'/>
        <PageSpinner/>
      </Fragment>
    );
  }
}
